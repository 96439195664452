import { useState, useEffect, useCallback } from 'preact/hooks'
import { route } from 'preact-router'

import getAuthPair from 'utils/get-auth-pair'
import sendRequest from 'utils/send-request'

const isAuthorizedFunc = async () => {
	const { login, pass } = getAuthPair()
	let result = false
	if (!!login && !!pass) {
		result = await sendRequest('/api/v1/auth/login', { method: 'POST', auth: { login, pass } })
	}
	return result
}

export default function useAuth() {
	const { login, pass } = getAuthPair()
	const redirectToLogin = useCallback(() => {
		route(`/login?backpath=${location.pathname}`, true)
	}, [location])
	const fetchAuth = () => {}
	const [ isAuth, setIsAuth ] = useState(false)
	const [ isAuthLoading, setIsAuthLoading ] = useState(true)

	useEffect(() => {
		setIsAuthLoading(true)
		isAuthorizedFunc().then(isAuth => {
			setIsAuth(isAuth)
			setIsAuthLoading(false)
		})
	}, [])

	return { login, pass, isAuth, isAuthLoading, redirectToLogin, fetchAuth }
}
