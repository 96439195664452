import { h } from 'preact'
import { getCurrentUrl } from 'preact-router'

// import { useState, useEffect } from 'preact/hooks'
// import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import './style.css'

const isIndexPage = (url) => url === '/'

const Logotype = () => {
	const isIndex = getCurrentUrl() === '/'
	// const curUrl = getCurrentUrl()
	// const [shown, setShown] = useState(!isIndexPage(curUrl))
	// const [bigLogoBottom, setBigLogoBottom] = useState(1000)

	// useEffect(() => {
	// 	setShown(!isIndexPage(curUrl))
	// }, [curUrl])

	// useScrollPosition(({ currPos }) => {
	// 	if (!isIndexPage(curUrl)) {
	// 		return null
	// 	}

	// 	const isShow = Math.abs(currPos.y) > bigLogoBottom
	// 	if (isShow != shown) {
	// 		setShown(isShow)
	// 	}
	// }, [shown, bigLogoBottom])

	// useLayoutEffect(() => {
	// 	if (!isIndexPage(curUrl)) {
	// 		return null
	// 	}

	// 	const handleResize = () => {
	// 		setBigLogoBottom(document.querySelector('.logotype').getBoundingClientRect().bottom)
	// 	}

	// 	window.addEventListener('resize', handleResize);

	// 	return () => {
	// 		window.removeEventListener('resize', handleResize);
	// 	};
	// }, [])

	return (
		<div className="logotype-mini">
			{h(
				isIndex ? 'span' : 'a',
				{
					className: 'logotype-mini__text',
					...(isIndex ? {} : { href: '/' }),
				},
				'Хиги́мо'
			)}
		</div>
	)
}

export default Logotype
