import { Link } from 'preact-router'

import OnlyAdmin from 'components/only-admin'
import TextContainer from 'components/text-container'

import './style.css'

const Footer = (props) => {
	if (props.isNotFound) {
		return null
	}

	return (
		<footer>
			<TextContainer className="footer">
				<div className="footer__column">
					<div className="footer__header"><a href="/project/">Сделал</a></div>
					<div className="footer__header">Делюсь знаниями</div>
					<div className="footer__link"><a href="https://t.me/rakovarnya_2_0">Раковарня 2.0</a></div>
					<div className="footer__link"><a href="https://t.me/efficient_and_happy">Техники → навыки → счастье</a></div>
					<div className="footer__link"><a href="https://t.me/screenshotil">Скриншотил</a></div>
					<OnlyAdmin><div className="footer__link"><a href="#">Интенсив</a></div></OnlyAdmin>
					<OnlyAdmin><div className="footer__link"><a href="/obuchenie/">Обучение</a></div></OnlyAdmin>
					<div className="footer__link"><a href="/feedback/">Багрепорты</a></div>
					<div className="footer__header">Связаться</div>
					<div className="footer__link"><a href="https://vk.com/higimo">ВКонтакте</a></div>
					<div className="footer__link"><a href="mailto:higimo@gmail.com">Электропочта</a></div>
					<div className="footer__link"><a href="https://instagram.com/higimo">Инста</a></div>
					<div className="footer__link"><a href="https://t.me/higimo">Телеграм</a></div>
					<div className="footer__link"><a href="https://github.com/higimo">Гитхаб</a></div>
				</div>
				<div className="footer__column">
					<div className="footer__header">Сделал сервисов</div>
					<div className="footer__link"><a href="/tourism/">Путешествую</a></div>
					<div className="footer__link"><a href="https://higimo.github.io/timer/build/">🕑 Калькулятор времени</a></div>
					<div className="footer__link"><a href="/list-list/main/">Список списков</a></div>
					<div className="footer__link"><a href="/clock/">Часы русского судного дня</a></div>
					<OnlyAdmin><div className="footer__link"><a href="/demagog/">Справочник демагога</a></div></OnlyAdmin>
					<OnlyAdmin><div className="footer__link"><a href="/nokia/">Нокиа</a></div></OnlyAdmin>
					<div className="footer__link"><a href="/tool/comoji/">Комоджи</a> (⌐■_■)</div>
					<div className="footer__link"><a href="/ttool/photo/">🖼 Фотографии во ВКонтакте</a></div>
					<div className="footer__link"><a href="/tool/emailer/">✉ Эмайлер</a></div>
					<div className="footer__link"><a href="/tool/magic/">Волшебный шар</a></div>
					<div className="footer__link"><a href="/tool/pet-project">Пробби</a></div>
					<OnlyAdmin><div className="footer__link"><a href="/pron/">Прон</a></div></OnlyAdmin>
					<div className="footer__link"><a href="https://deploy-calendar.ru/">Календарь деплоя</a></div>
					<div className="footer__link"><a href="https://t.me/HeyRoleBot">Бот упоминаний</a></div>
					<div className="footer__link"><a href="https://t.me/taskDollBot">🪆 Бот трёх заданий</a></div>
				</div>
				<div className="footer__column">
					<div className="footer__header">Храню знания</div>
					<OnlyAdmin><div className="footer__link"><a href="/things/">Мои вещи</a></div></OnlyAdmin>
					<div className="footer__link"><a href="/faq/">FAQ</a></div>
					<div className="footer__link"><a href="/game/">Настольные игры</a></div>
					<div className="footer__link"><a href="https://www.pinterest.at/higim0/список-желаний/">Список желаний</a></div>
					<div className="footer__link"><a href="/accord/">Аккорды</a></div>
					<div className="footer__link"><a href="/cinema/">Кинолог</a></div>
					<div className="footer__link">Избранные <a href="/youtube/">видосы</a> и <a href="/links/">ссылки</a></div>
					<div className="footer__link"><a href="/logism/">☝ Логизмы</a></div>
					<div className="footer__link footer__link--copyright">Сделал Хиги́мо</div>
				</div>
			</TextContainer>
		</footer>
	)
}

export default Footer
