import OnlyAdmin     from 'components/only-admin'
import TextContainer from 'components/text-container'
import LogotypeMini  from 'components/logotype-mini'

import './style.css'

const menu = [
	{
		href: '/project/',
		name: 'Сделал',
	},
	{
		href: '/list-list/main/',
		name: 'Спискота',
	},
	{
		href: '/accord/',
		name: 'Аккорды',
	},
	{
		href: '/tourism/',
		name: 'Путешествую',
	},
]

const Header = (props) => {
	if (props.isNotFound) {
		return null
	}

	return (
		<header className="main-header">
			<div className="main-header__container">
				<a href="/" className="main-header__link">
					<img className="main-header__avatar" src="/img/avatar.jpg" />
				</a>
				<LogotypeMini />
				<div className="main-header__menu">
					{menu.map(i => <a href={i.href} key={i.href}>{i.name}</a>)}
					<OnlyAdmin><a href="/nokia/">Нокиа</a></OnlyAdmin>
					<OnlyAdmin><a href="/admin/">Админ</a></OnlyAdmin>
				</div>
			</div>
		</header>

	)
}

export default Header
