import {createContext} from 'preact'
import {useContext} from 'preact/hooks'

const Auth = createContext('auth')

const AuthProvider = ({isAuth, children}) => (
	<Auth.Provider value={{isAuth}}>
		{children}
	</Auth.Provider>
)

const useAuth = () => {
	return useContext(Auth)
}

export {AuthProvider, useAuth}
